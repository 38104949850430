<template>
  <v-container>
    <v-toolbar dense flat>
      <v-switch v-model="filter.ignored" inset label="ignorierte" hideDetails
        >ignorierte</v-switch
      >

      <LookupValueInput
        class="ml-4"
        label="Typ"
        :items="types"
        hideDetails
        clearable
        v-model="filter.type"
      ></LookupValueInput>
      <v-spacer></v-spacer>
      <v-btn outlined color="primary" @click="getData">aktualisieren</v-btn>
    </v-toolbar>

    <v-card class="mt-2"
      ><v-system-bar
        >Aufgaben<v-spacer />
        {{ this.itemsSearched.length }} Einträge</v-system-bar
      >
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="itemsSearched"
        :items-per-page="-1"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:item.person="{ item }">
          <PersonItem v-if="item.person" small :value="item.person" />
          <span v-else>{{ item.objectName }}</span>
        </template>
        <template v-slot:item.property="{ item }">
          <LookupValue :value="item.property" />
        </template>
        <template v-slot:item.type="{ item }">
          <LookupValue :value="item.type" />
        </template>
        <template v-slot:item.job="{ item }">
          <v-tooltip v-if="item.job" top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on">{{ jobIcon(item.job) }}</v-icon>
            </template>
            <span>{{ item.job.name }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.action="{ item }">
          <v-speed-dial left direction="left">
            <template v-slot:activator>
              <v-btn text>
                <v-icon> mdi-dots-horizontal </v-icon>
              </v-btn>
            </template>

            <v-btn small color="success" dark @click="doneTask(item)"
              >erledigt</v-btn
            >
            <v-btn
              v-if="!item.ignored"
              small
              color="danger"
              dark
              @click="ignoreTask(item, true)"
              >ignorieren</v-btn
            ><v-btn
              v-else
              small
              color="danger"
              dark
              @click="ignoreTask(item, false)"
              >nicht mehr ignorieren</v-btn
            >
          </v-speed-dial>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { defineComponent } from "vue";
import LookupValue from "common/components/LookupValue.vue";
import PersonItem from "common/components/PersonItem.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";

import { compareLookups, searchLookup } from "common/utils/helper.js";
import { jobIcon } from "common/utils/icons.js";
import {
  comparePeople,
  searchPerson,
  personName,
} from "common/utils/people.js";

export default defineComponent({
  name: "JobsList",
  components: { LookupValueInput, LookupValue, PersonItem },
  props: ["search"],
  data: () => ({
    loading: false,
    items: [],
    headers: [
      { text: "Person", value: "person", sort: comparePeople },
      { text: "Beschreibung", value: "description", sort: compareLookups },
      { text: "Quelle", value: "job" },
      { text: "", value: "action" },
    ],
    filter: {
      ignored: false,
      type: null,
    },
  }),
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (item) =>
          this.filter.ignored == item.ignored &&
          (!this.filter.type ||
            (this.filter.type && item.type.id == this.filter.type.id))
      );
    },
    itemsSearched() {
      return this.itemsFiltered.filter(
        (item) =>
          !this.search ||
          searchLookup(item.type, this.search) ||
          searchPerson(item.person, this.search) ||
          (item.objectName &&
            item.objectName.toLowerCase().includes(this.search.toLowerCase()))
      );
    },
    types() {
      return [
        ...new Set(
          this.items
            .filter((el) => el.ignored == this.filter.ignored)
            .map((el) => el.type)
        ),
      ].sort((a, b) => a.code.localeCompare(b.code));
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        localStorage.setItem(
          "automationTasksFilter",
          JSON.stringify(this.filter)
        );
      },
    },
  },
  methods: {
    jobIcon,
    async getData() {
      this.loading = true;
      this.items = await this.apiList({ resource: "automation/manualtask" });
      this.loading = false;
    },

    async doneTask(task) {
      if (
        await this.$root.confirm({
          message: `Soll die Aufgabe «${task.type.description}» betreffend «${
            task.person ? personName(task.person) : task.objectName
          }» als erledigt markiert werden?`,
          color: "success",
          icon: "mdi-check",
        })
      ) {
        await this.apiDelete({
          resource: "automation/manualtask",
          id: task.id,
        });
        this.$root.showSuccess(`Aufgabe als erledigt markiert!`);
        this.getData();
      }
    },
    async ignoreTask(task, value) {
      await this.apiPatch({
        resource: "automation/manualtask",
        id: task.id,
        key: "ignored",
        value: value,
      });
      this.$root.showSuccess(`Aufgabe wird ignoriert!`);
      this.getData();
    },
  },
  async created() {
    this.getData();
    if (localStorage.getItem("automationTasksFilter")) {
      this.filter = JSON.parse(localStorage.getItem("automationTasksFilter"));
    }
  },
});
</script>
